import React from "react";
import { Route } from "react-router-dom";
import { useAuth } from "../core/Context/LoginContext";
import LoginPage from "../pages/LoginPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  // const { auth } = useContext(authContext);
  const { authValues } = useAuth();
  const { loading } = authValues;

  if (loading) {
    return (
      <Route
        {...rest}
        render={() => {
          return <p>Loading...</p>;
        }}
      />
    );
  }
  // if loading is set to true (when our function useEffect(() => {}, []) is not executed), we are rendering a loading component;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        return authValues.user ? <Component {...routeProps} /> : <LoginPage />;
      }}
    />
  );
};

export default PrivateRoute;
