import React, {useState, useContext, useEffect} from 'react';
import { loginConvencion } from '../api/accountApi';

const AuthContext = React.createContext<any>(undefined);

export function useAuth() {
    return useContext(AuthContext);
}

export const AuthProvider: React.FC = ({ children }) => {
    const [authValues, setAuthValues] = useState<any>({
        loading: true,
        user: null,
    });

    const setAuthData = (status: boolean, dataUser: any) => {
        setAuthValues({loading: status, user: dataUser});
    };

    useEffect(() => {
        const dataFromLocalStorage = window.localStorage.getItem('authData');
        setAuthValues({ loading: false, user: (dataFromLocalStorage) ? JSON.parse(dataFromLocalStorage) : null});
      }, []);

    useEffect(() => {
        window.localStorage.setItem('authData', JSON.stringify(authValues.user));
    }, [authValues.user]);

    const login = async ({user, password}: {user:string; password:string}) => {

        const result = await loginConvencion({user, pwd:password});
        
        return new Promise((resolve) => {
            if (result.data.response.logged && result.data.response.enable === 1) {
                setAuthValues({
                    loading: false,
                    user: {logged:true, token: result.data.response.token},
                });
                resolve({status:true, error:0});
            } else {
                if (result.data.response.enable === 0) {
                    resolve({status:false, error:2});
                } else {
                    resolve({status:false, error:1});
                }
                
                
            }
        });
        
    };

    const logout = () => {
        setAuthValues({
            loading: false,
            user: null,
        });
        window.location.href='./login'
    };

    let values = {
        authValues,
        login, 
        logout,
        setAuthData,
    }

    return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;

};

// export default Context;