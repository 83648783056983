import React, { useState, useEffect } from 'react';
import { useJwt } from 'react-jwt';
import { useForm, Controller } from "react-hook-form";
import {
  IonFabButton, 
  IonFab, 
  IonCard, 
  IonText, 
  IonAvatar, 
  IonCardHeader, 
  IonButton, 
  IonCardTitle, 
  IonCardContent, 
  IonContent, 
  IonHeader, 
  IonPage, 
  IonToolbar, 
  IonInput, 
  IonItem, 
  IonLabel, 
  IonList, 
  useIonAlert,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonPopover,
  IonListHeader,
} from '@ionic/react';
import './RegistroResultados.css';
import { person } from 'ionicons/icons';

import convencionApi from '../core/api/convencionApi';
import { useAuth }  from '../core/Context/LoginContext';

// set the default values for the controls
let initialValuesJrv = {
  jrv: "",
  res_001: "",
  res_002: "",
  res_003: "",
  res_004: "",
  res_005: "",
  res_vb: "",
  res_vn: "",
};

interface AuthData {
  logged: boolean;
  token: string;
}

const RegistroResultados: React.FC = () => {
  const { logout} = useAuth();
  const { control, reset, handleSubmit, formState: { errors } } = useForm({
    defaultValues: initialValuesJrv
  });
  const [present] = useIonAlert();
  const [jrv, setJrv] = useState<number>(0);
  const [totalElectores, setTotalElectores] = useState<number>(0);
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

  const [user, setUser] = useState<any>([]);

  const lAuthData = localStorage.getItem('authData');
  let authData: AuthData = JSON.parse(lAuthData || '{}');
  const { decodedToken } = useJwt(authData ? authData.token : '');

  useEffect(() => {
    if (decodedToken !== null) {
      setUser(decodedToken);
    }
  }, [decodedToken]);

  const searchJRV = (data: any) => {
    let jrvToSend = data.jrv;
    if (user.tipo_usuario === 'CON') {
      jrvToSend = user.jrv;
    }
    convencionApi.searchJrv(jrvToSend).then((response: any)=>{
      if (response.data.cantidad > 0) {
        setJrv(jrvToSend);
        setTotalElectores(response.data.totalElectores);
        setShowGrid(true);
      } else {
        present({
          header: 'Error',
          message: `La JRV N° ${jrvToSend} no se encuentra registrada.`,
          buttons: [            
            {text: 'Cerrar'},
          ],          
        })
        setShowGrid(false);
      }
    }).catch((error:string) => {
      present({
        header: 'Error',
        message: `${error}`,
        buttons: [            
          {text: 'Cerrar'},
        ],          
      })
    });

  };

  const saveResultado = (data: any) => {
    const dataResultado = {...data, cod_usuario:user.num_cedula, jrv};    
    const res001 = dataResultado.res_001 === "" ? 0 : parseInt(dataResultado.res_001);
    const res002 = dataResultado.res_002 === "" ? 0 : parseInt(dataResultado.res_002);
    const res003 = dataResultado.res_003 === "" ? 0 : parseInt(dataResultado.res_003);
    const res004 = dataResultado.res_004 === "" ? 0 : parseInt(dataResultado.res_004);
    const res005 = dataResultado.res_005 === "" ? 0 : parseInt(dataResultado.res_005);
    const resVb  = dataResultado.res_vb  === "" ? 0 : parseInt(dataResultado.res_vb);
    const resVn  = dataResultado.res_vn  === "" ? 0 : parseInt(dataResultado.res_vn);

    const totalRegistros = res001 + res002 + res003 + res004 + res005 + resVb + resVn;

    if (totalRegistros <= totalElectores) {

      convencionApi.saveResultados(dataResultado).then((result: any)=>{
        
        if (result.data.response) {
          present({
            header: 'Resultado',
            message: `Los resultados para la JRV N° ${jrv} se almacenaron correctamente.`,
            buttons: [            
              {text: 'Cerrar', handler: (d) => setShowGrid(false)},
            ],          
          })
        } else {
          present({
            header: 'Error',
            message: `No se ha podido guardar los resultados para la JRV N° ${jrv}. Vuelva a intentar más tarde.`,
            buttons: [            
              {text: 'Cerrar'},
            ],          
          })
          setShowGrid(true);
        }
        reset({
          res_001: "",
          res_002: "",
          res_003: "",
          res_004: "",
          res_005: "",
          res_vb: "",
          res_vn: "",
        });
      });
    } else {
      present({
        header: 'Error',
        message: `La cantidad de registros supera la cantidad de electores permitidos para la JRV N° ${jrv}.`,
        buttons: [            
          {text: 'Cerrar'},
        ],          
      })
      setShowGrid(true);
    }

  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonGrid >
          <IonRow>
            <IonCol style={{textAlign:'left', padding:0}}>
              <img src="./assets/img/ico-pln-white.png" alt="pln-logo-white" className="headerImg" />
            </IonCol>
            <IonCol>
              <div className="mainHeader">Registro de Resultados</div>         
            </IonCol>
            <IonCol style={{textAlign:'right', padding:0}}>
            <IonIcon icon={person} className="userSesion" onClick={
        (e: any) => {
          e.persist();
          setShowPopover({ showPopover: true, event: e })
        }}/> 
            <IonPopover        
                event={popoverState.event}
                isOpen={popoverState.showPopover}
                onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
            >
            <IonList>
              <IonListHeader><strong>En Sesion</strong></IonListHeader>
              <IonItem>{user.des_nombre}</IonItem>
              <IonItem button onClick={logout}>Cerrar Sesión</IonItem>
            </IonList>
      </IonPopover>
              
                               
              
              
            </IonCol>
          </IonRow> 
        </IonGrid>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
          {!showGrid && (
            <div className="container">
            <IonCard>
              <IonCardHeader>
                {user.tipo_usuario === 'CC' ? (
                  <IonCardTitle className="cardTitle"><strong>Indique la JRV a Registrar</strong></IonCardTitle>
                ) : (
                  <IonCardTitle><strong className="cardTitle">Centro de Votación</strong><br />{user.centroVotacion}</IonCardTitle>
                )}
              </IonCardHeader>

              <form onSubmit={handleSubmit(searchJRV)} >
              <IonCardContent>
                <IonList>
                <IonItem>    
                  {user.tipo_usuario === 'CC' ? (              
                  <Controller
                    render={({ field: { onChange, value } }) => (<IonInput type="number" onIonChange={value => onChange(value)} className="jrvInput" />)}
                    control={control}
                    name="jrv"
                    rules={{ required: true }}
                  />     
                  ) : (
                    <div className="jrvInputText">{user.jrv}</div>
                  )}
                </IonItem>
                <IonText color="danger">
                  <h4 >{errors.jrv?.type === 'required' && "JRV es requerido"}</h4>
                </IonText>
                </IonList>
                <IonButton type="submit" color="secondary" className="btnAction">Abrir</IonButton>
              </IonCardContent>
              </form>
            </IonCard>
            </div>
          )}
          {showGrid && (
            <div className="formContainer">
              <form onSubmit={handleSubmit(saveResultado)} >
              <IonFab vertical="top" horizontal="end" slot="fixed" style={{marginRight:110}}>
                <IonFabButton color="secondary"><strong style={{color:'#fff'}}>TE <br />{totalElectores}</strong></IonFabButton>
              </IonFab>
              <IonFab vertical="top" horizontal="start" slot="fixed" style={{marginLeft:110}}>                
                <IonFabButton><strong style={{color:'#fff'}}>JRV <br />{jrv}</strong></IonFabButton>
              </IonFab>
              <IonCard>
                <IonCardHeader style={{marginTop:60}}>
                  <IonText color="secondary" style={{textAlign:'center'}}>
                    <h5><strong>Digite el resultado para cada candidato</strong></h5>
                  </IonText>
                </IonCardHeader>

                <IonCardContent style={{marginTop:-18}}>
                  <IonList>
                    <IonItem>
                      <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                        <img src="./assets/img/C1.png" alt="Alpizar Otoya Claudio Alberto"/>
                      </IonAvatar>
                      <IonLabel position="floating">Alpizar Otoya Claudio Alberto</IonLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_001"
                      />
                    </IonItem>
                    <IonItem>
                      <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                        <img src="./assets/img/C2.png" alt="Araya Monge Rolando"/>
                      </IonAvatar>
                      <IonLabel position="floating">Araya Monge Rolando</IonLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_002"
                      />                      
                    </IonItem>
                    <IonItem>
                      <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                        <img src="./assets/img/C3.png" alt="Benavides Jiménez Carlos Ricardo"/>
                      </IonAvatar>
                      <IonLabel position="floating">Benavides Jiménez Carlos Ricardo</IonLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_003"
                      />                      
                    </IonItem>
                    <IonItem>
                      <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                        <img src="./assets/img/C4.png" alt="Figueres Olsen José María"/>
                      </IonAvatar>
                      <IonLabel position="floating">Figueres Olsen José María</IonLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_004"
                      />  
                    </IonItem>
                    <IonItem>
                      <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                        <img src="./assets/img/C5.png" alt="Thompson Chacón Roberto Hernán"/>
                      </IonAvatar>
                      <IonLabel position="floating">Thompson Chacón Roberto Hernán</IonLabel>
                      <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_005"
                      /> 
                    </IonItem>                    
                  </IonList>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonText color="secondary">
                    <h5><strong>Registro de Blancos y Nulos</strong></h5>
                  </IonText>
                </IonCardHeader>
                <IonCardContent style={{marginTop:-18}}>
                <IonList>
                  <IonItem>
                    <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                      <img src="./assets/img/blanks.png" alt="Blancos"/>
                    </IonAvatar>
                    <IonLabel position="floating">Blancos</IonLabel>
                    <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_vb"
                      />                    
                  </IonItem>
                  <IonItem>
                    <IonAvatar slot="start" style={{width:'50px', height:'50px'}}>
                      <img src="./assets/img/nulls.png" alt="Nulos"/>
                    </IonAvatar>
                    <IonLabel position="floating">Nulos</IonLabel>
                    <Controller
                        render={({ field: { onChange, value } }) => (<IonInput type="number" className="inputResult" placeholder="Ingrese el Resultado"  onIonChange={value => onChange(value)} />)}
                        control={control}
                        name="res_vn"
                      />
                  </IonItem>
                  <IonButton type="submit" color="secondary" className="btnAction">Guardar</IonButton>               
                </IonList>
              </IonCardContent>
              </IonCard>
            </form>
            </div>
          )}
        
      </IonContent>
    </IonPage>
  );
};

export default RegistroResultados;
