import React, { useState } from 'react';
import { useForm, Controller } from "react-hook-form";
import {
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent, 
    IonButton,
    IonCard, 
    IonText, 
    IonCardHeader,
    IonCardTitle, 
    IonCardSubtitle,
    IonCardContent,
    IonInput, 
    IonItem, 
    IonList, 
    IonLabel,
    IonLoading,
    useIonAlert,
    IonAvatar,
} from '@ionic/react';
import { useAuth }  from '../core/Context/LoginContext';
import { useHistory } from 'react-router';

// set the default values for the controls
let initialValuesJrv = {
    user: "",
    pwd: "",
  };
export const LoginPage: React.FC = () => {    
    const { login } = useAuth();
    const history = useHistory();
    const [present] = useIonAlert();

    const [showLoading, setShowLoading] = useState(false);

    const { control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: initialValuesJrv
    });

    const doLogin =  (data: any) => {
        setShowLoading(true);
        login({user:data.user, password:data.pwd}).then((result: any) => {
            setShowLoading(false);
            if (result.status) {
                history.replace("/registro-resultados");
            } else if (result.error === 2) {                
                present({
                    header: 'Acceso Bloqueado',
                    message: `En estos momentos el acceso se encuentra Inhabilitado`,
                    buttons: [            
                      {text: 'Cerrar'},
                    ],          
                });
            } else {
                present({
                    header: 'Error',
                    message: `Usuario o Contraseña inválidos`,
                    buttons: [            
                      {text: 'Cerrar'},
                    ],          
                });
            }
        });
    };

    return (
        <IonPage>
            <IonHeader>
            <IonToolbar>
                <div>
                    
                    <IonTitle className="mainHeader">Convención PLN</IonTitle>
                </div>
            </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <div className="container">
                    <IonLoading
                        cssClass='my-custom-class'
                        isOpen={showLoading}
                        onDidDismiss={() => setShowLoading(false)}
                        message={'Ingresando...'}
                        duration={3000}
                    />                    
                    <IonCard>
                        <IonCardHeader>
                        <IonAvatar style={{margin: '0 auto'}}>
                            <img src="./assets/img/login-pln-logo.png" alt="Logo PLN" />
                        </IonAvatar>
                            <IonCardTitle><strong>Inicio de Sesión</strong></IonCardTitle>
                        </IonCardHeader>

                        <form onSubmit={handleSubmit(doLogin)} >
                        <IonCardContent>
                            <IonList>
                                <IonItem>
                                    <IonLabel position="floating">Usuario</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, value } }) => (<IonInput  onIonChange={value => onChange(value)} />)}
                                        control={control}
                                        name="user"
                                        rules={{ required: true }}
                                    />               
                                </IonItem>
                                <IonText color="danger">
                                    <h4 >{errors.user?.type === 'required' && "El usuario es requerido"}</h4>
                                </IonText>
                                <IonItem>
                                    <IonLabel position="floating">Contraseña</IonLabel>
                                    <Controller
                                        render={({ field: { onChange, value } }) => (<IonInput type='password'  onIonChange={value => onChange(value)} />)}
                                        control={control}
                                        name="pwd"
                                        rules={{ required: true }}
                                    />               
                                </IonItem>
                                <IonText color="danger">
                                    <h4 >{errors.pwd?.type === 'required' && "La contraseña es requerida"}</h4>
                                </IonText>
                            </IonList>
                            <IonButton type="submit" color="secondary" className="btnAction">Ingresar</IonButton>
                        </IonCardContent>
                        </form>
                        <IonCardHeader>
                            <IonCardSubtitle>Partido Liberación Nacional</IonCardSubtitle>
                            <IonCardSubtitle>&copy; Derechos Reservados 2021</IonCardSubtitle>
                        </IonCardHeader>
                    </IonCard>
                </div>                
            </IonContent>
        </IonPage>
    );

};

export default LoginPage;