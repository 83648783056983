import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export const loginConvencion = (params) => {
  const { user, pwd } = params;

  const profileData = {
    user,
    pwd,
  };

  return axios.post("/auth/loginConvencion", profileData);
};

export const addUser = (params) => {
  const { uid, email } = params;

  const profileData = {
    auth_id: uid,
    email,
  };

  return axios.post("/profiles", profileData);
};

export const getProfile = (params) => {
  const { token, uid } = params;

  const options = {
    headers: { "Auth-Token": token },
  };

  const url = [`/profiles/${uid}`];

  return axios.get(url.join("&"), options);
};
