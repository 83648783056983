import React from 'react';
import { Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { server } from 'ionicons/icons';
import RegistroResultados from './pages/RegistroResultados';
import LoginPage from './pages/LoginPage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/custom.css';

import { useAuth }  from './core/Context/LoginContext';
import PrivateRoute from './components/PrivateRoute';

const App: React.FC = () => {

  const { authValues } = useAuth();

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet>
            <PrivateRoute path="/" component={RegistroResultados} />
            <Route path="/login" component={LoginPage} />
          </IonRouterOutlet>
          
          <IonTabBar slot="bottom">
          
            {authValues.user && (
              <IonTabButton tab="registro-resultados" href="/registro-resultados">
                <div onClick={reloadPage} className="wrapLink">
                  <IonIcon icon={server} className="wrapedIcon" />
                  <IonLabel>Registro</IonLabel>
                </div>
              </IonTabButton>
            )}
          
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );

};

export default App;
