/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  responseType: "json",
});

const searchJrv = (jrv) => {
  return axiosInstance.get(`convencion/searchJrv/${jrv}`);
};

const saveResultados = (_info) => {
  const data = {
    resultados: _info,
  };
  return axiosInstance.post("convencion/saveResultados", data);
};

export default {
  searchJrv,
  saveResultados,
};
